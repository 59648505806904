<template>
    <section class="llamados-crear mt-4 ml-4">
        <cargando v-if="cargando" />
        <div class="row mt-4 my-4">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                    <el-breadcrumb-item :to="{ name: 'correspondencia.listado' }">
                        Correspondencia
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>Reportar</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <ValidationObserver v-slot="{ invalid }" ref="validation">
            <div class="row mt-5 mx-5">
                <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div class="row">
                        <p class="f-15 f-500 pl-2">Tipo de correspondencia</p>
                        <div class="col-12 col-sm-12 mb-3 my-2col-12 col-sm-12 my-2">
                            <div class="row mx-0">
                                <div class="wh-40px rounded-circle d-middle-center my-auto">
                                    <img :src="imagenTipo" alt="" width="35" height="35" class="br-20" />
                                </div>
                                <div class="col">
                                    <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="tipo" class="col-12 col-sm-12 mb-3 my-2">
                                        <el-select v-model="correspondencia.idm_icono" placeholder="Seleccionar tipo" size="small" class="w-100" @change="tipoSelect">
                                            <el-option v-for="(tipo,i) in tipos" :key="i" :label="tipo.nombre" :value="tipo.id" />
                                        </el-select>
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <p class="f-15 f-500 pl-2 my-3">Destinatario</p>
                        <ValidationProvider tag="div" name="vivienda" class="col-12 col-sm-12 mb-3 my-2">
                            <p class="f-12 pl-2 label-inputs text-validation">Grupo de viviendas</p>
                            <select-grupos @setAgrupacion="setAgrupacion" />    
                        </ValidationProvider>
                        <div class="col-12 col-sm-12 mb-3">
                            <p class="f-12 pl-2 label-inputs text-validation">Vivienda</p>
                            <select-viviendas ref="selectViviendas" @setVivienda="setVivienda" />
                        </div>
                        <p class="f-15 f-500 pl-2 my-3">Quien entrega</p>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:1000" name="descripción" tag="div" class="col-12 col-sm-12 mb-3">
                            <p class="f-12 pl-2 label-inputs text-validation">Nombre</p>
                            <el-input v-model="correspondencia.entrega_nombre" placeholder="Nombre" show-word-limit maxlength="120" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:500" name="recomendaciones" tag="div" class="col-12 col-sm-12 mb-3">
                            <p class="f-12 pl-2 label-inputs text-validation">Observaciones</p>
                            <el-input v-model="correspondencia.observaciones" placeholder="Observaciones" type="textarea" rows="4" show-word-limit maxlength="500" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <div class="col-12">
                            <button class="btn btn-general h-32px f-14 f-300 px-5" @click="guardar">
                                Reportar
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-5 mx-4">
                    <p class="col-12 f-20 f-500 mb-3">Imágenes de soporte</p>
                    <el-upload
                    action="#"
                    list-type="picture-card"
                    :file-list="imagenes"
                    :on-change="handleChange"
                    :multiple="false"
                    :auto-upload="false"
                    >
                        <i slot="default" class="el-icon-plus" />
                        <div slot="file" slot-scope="{file}">
                            <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url" alt=""
                            />
                            <span class="el-upload-list__item-actions">
                                <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                                >
                                    <i class="el-icon-zoom-in" />
                                </span>
                                <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="removeImage(file)"
                                >
                                    <i class="el-icon-delete" />
                                </span>
                            </span>
                        </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>  
                </div>
            </div>
        </ValidationObserver>
        <!-- Partials -->
    </section>
</template>
<script>
import Correspondencias from '~/services/correspondencia'
export default {
    components:{
    },
    data(){
        return{
            cargando: false,
            correspondencia: {
                idm_icono: null,
                id_vivienda: '',
                entrega_nombre: '',
                observaciones: ''
            },
            tipos: [],
            imagenTipo: '/img/no-imagen/noimagenanuncio.svg',
            imagenes:[],
            disabled: false,
            dialogImageUrl: '',
            dialogVisible: false,
            idEliminarImagen: null,
        }
    },
    created(){
        this.tiposCorrespondencias()
    },
    methods:{
        verImg(){
            this.$refs.abrirModalVerImg.toggle()
        },
        eliminarImg(idx){
            this.listadoImagenes.splice(idx, 1);
        },
        setAgrupacion(id){
            this.$refs.selectViviendas.setIdPadre(id)
        },
        setVivienda(id){
            this.correspondencia.id_vivienda = id
        },
        handleChange(file, fileList){    
            const isIMG = (file.raw.type === 'image/png' || file.raw.type === 'image/jpeg');
            this.imagenes = fileList

            if (!isIMG){
                this.$message.error('Solo se permiten imágenes')
                this.removeImage(file)
            }

        },
        handlePictureCardPreview(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file){
            console.log(file);
        },
        removeImage(file){
            let index = _.findIndex(this.imagenes, { 'uid': file.uid })
            this.$delete(this.imagenes, index)
        },
        async tiposCorrespondencias(){
            try {
                const {data} = await Correspondencias.tipos()
                this.tipos = data.data;
            } catch (e){
                this.errorCatch(e)
            }
        },
        tipoSelect(idTipo){
            let obj = this.tipos.find(el=>el.id==idTipo)
            this.imagenTipo = obj.icono
        },
        async guardar(){
            try {
                const valid = await this.$refs.validation.validate()
                if(!valid) return this.notificacion('','Por favor complete todos los campos')
                if (this.correspondencia.id_vivienda == '') return this.notificacion('','Por favor complete los campos de la vivienda');

                this.cargando = true

                let formData = new FormData();
                formData.append("idm_icono", this.correspondencia.idm_icono)
                formData.append("id_vivienda", this.correspondencia.id_vivienda)
                formData.append("entrega_nombre", this.correspondencia.entrega_nombre)
                formData.append("observaciones", this.correspondencia.observaciones)
                this.imagenes.forEach(imagen => {
                    formData.append("imagenes[]", imagen.raw)
                })
                
                const {data} = await Correspondencias.guardar(formData)
                
                if(data.success){
                    this.$notify({
                        title: 'Guardar',
                        message: data.mensaje,
                        type: 'success'
                    })
                    this.$router.push({ name: 'correspondencia.listado' })
                }else{
                    this.$notify({
                        title: 'Guardar',
                        message: data.mensaje,
                        type: 'warning'
                    });
                }
            } catch (e){
                this.errorCatch(e)
                this.$notify({
                    title: 'Error',
                    message: 'Todos los campos son requeridos',
                    type: 'warning'
                });
            } finally{
                this.cargando = false
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.llamados-crear{
    .container-img{
        width: 120px;
        height: 120px;
        position: relative;
        i.icon-me_interesa{
            position: absolute;
            right: 3px;
            top: 3px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        .opciones{
            position: absolute;
            bottom: 7px;
            left: 36px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        &:hover{
            .opciones, .icon-me_interesa{
                transition: .45s;
                opacity: 1;
            }
        }
    }
}
</style>